import Head from 'next/head';
import { useRouter } from 'next/router';
import { ChakraProvider } from '@chakra-ui/react';
import { ApolloProvider } from '@apollo/react-hooks';
import Authorized from '../components/templates/Authorized';
import withApollo from '../lib/hasura/withApollo';
import createApolloClient from '../lib/hasura';
import { initSentry } from '../lib/sentry';

initSentry();

const GlobalStyles = () => (
  <style jsx global>{`
    .omx-bg-dark {
      background: #171923 !important;
      background-attachment: fixed;
      background: linear-gradient(
        36deg,
        #212333 0%,
        rgb(32 27 44) 35%,
        rgb(29 17 26) 100%
      ) !important;
    }
    .omx-bg-light {
      background: #edf2f7 !important;
      background-attachment: fixed;
      background: linear-gradient(
        36deg,
        #d7e5f3 0%,
        rgb(239 239 239) 35%,
        rgb(253 238 251) 100%
      ) !important;
    }
  `}</style>
);

const publicPages = ['/login', '/404', '/500'];

const HeadTags = () => (
  <Head>
    <title>Omnixent Cloud</title>
    <meta property="og:title" content="Omnixent Cloud" />
    <meta property="og:type" content="Omnixent Cloud" />
    <meta property="og:url" content="https://cloud.omnixent.com" />
    <meta property="og:image" content="https://cloud.omnixent.com/imgs/og/omx-og.png" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@omnixent" />
    <meta name="twitter:creator" content="@omnixent" />
    <meta name="twitter:title" content="Omnixent Cloud" />
    <meta name="twitter:image" content="https://cloud.omnixent.com/imgs/og/omx-og.png" />
  </Head>
);

function Omnixent({ Component, pageProps }) {
  const { pathname } = useRouter();

  if (publicPages.includes(pathname)) {
    return (
      <ChakraProvider>
        <HeadTags />
        <GlobalStyles />
        <Component {...pageProps} />
      </ChakraProvider>
    );
  }

  return (
    <ApolloProvider client={createApolloClient()}>
      <ChakraProvider>
        <HeadTags />
        <GlobalStyles />
        <Authorized>
          <Component {...pageProps} />
        </Authorized>
      </ChakraProvider>
    </ApolloProvider>
  );
}

export default withApollo(Omnixent);
