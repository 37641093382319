import { Box } from '@chakra-ui/layout';
import { useColorMode } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import Header from '../../organisms/Header';
import Navbar from '../../organisms/ Navbar';

export default function Authorized(props) {
  const { colorMode } = useColorMode();

  return (
    <>
      <Box width="full" minH="100vh" bg={useColorModeValue('white', 'gray.900')}>
        <Box>
          <Navbar />
          <Box ml={300}>
            <Header />
            <Box p={50}>{props.children}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
