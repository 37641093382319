import Link from 'next/link';
import { useRouter } from 'next/router';
import { Image, useColorMode, useColorModeValue, Button, Icon } from '@chakra-ui/react';
import { Flex, Stack, Box, Divider } from '@chakra-ui/layout';
import { ViewIcon, UnlockIcon } from '@chakra-ui/icons';
import { RiBookMarkFill } from 'react-icons/ri';
import { IoSpeedometerSharp } from 'react-icons/io5';
import { BiHelpCircle } from 'react-icons/bi';
import { ImGithub } from 'react-icons/im';
import { HiCode } from 'react-icons/hi';

const navButtonsPrimary = [
  {
    label: 'Ask Omnixent',
    href: '/',
    icon: <ViewIcon />,
  },
  {
    label: 'Saved Queries',
    href: '/saved',
    icon: <Icon as={RiBookMarkFill} />,
  },
  {
    label: 'Plan',
    href: '/plan',
    icon: <Icon as={IoSpeedometerSharp} />,
  },
  {
    label: 'API',
    href: '/developer-area',
    icon: <Icon as={HiCode} />,
  },
  {
    label: 'Logout',
    href: '/api/logout',
    icon: <UnlockIcon />,
  },
];

const navButtonsSecondary = [
  {
    label: 'Support',
    href: 'mailto:info@omnixent.com',
    icon: <Icon as={BiHelpCircle} />,
  },
  {
    label: 'Open-source',
    href: 'https://github.com/omnixent/omnixent',
    icon: <Icon as={ImGithub} />,
  },
];

export default function Navbar() {
  const { colorMode } = useColorMode();
  const router = useRouter();
  const { asPath } = router;

  return (
    <Flex
      p={25}
      justifyContent="flex-start"
      alignItems="flex-start"
      flexDirection="column"
      position="fixed"
      h="100vh"
      bgColor={useColorModeValue('gray.100', 'gray.800')}>
      <Box w="full">
        <Image src={`/imgs/omx-logo-${colorMode}.svg`} width={225} margin="auto" />
        <Flex mt={50} w="full" flexDir="column">
          <Stack spacing={[3]} w="full">
            {navButtonsPrimary.map((item) => (
              <Link href={item.href} key={item.label}>
                <Button
                  leftIcon={item.icon}
                  size="sm"
                  w="full"
                  variant={asPath === item.href ? 'solid' : 'ghost'}
                  justifyContent="flex-start">
                  {item.label}
                </Button>
              </Link>
            ))}
          </Stack>
          <Divider mt={5} mb={5} />
          <Stack spacing={[3]} w="full">
            {navButtonsSecondary.map((item) => (
              <Link href={item.href} key={item.label}>
                <Button
                  leftIcon={item.icon}
                  size="sm"
                  w="full"
                  variant="ghost"
                  justifyContent="flex-start">
                  {item.label}
                </Button>
              </Link>
            ))}
          </Stack>
        </Flex>
      </Box>
    </Flex>
  );
}
