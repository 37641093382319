import withApollo from 'next-with-apollo';
import { ApolloProvider } from '@apollo/react-hooks';
import createApolloClient from './';

export default withApollo(({ initialState }) => createApolloClient(initialState), {
  render: ({ Page, props }) => {
    return (
      <ApolloProvider client={props.apollo}>
        <Page {...props} />
      </ApolloProvider>
    );
  },
});
