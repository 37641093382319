import fetch from 'isomorphic-unfetch';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

let accessToken = null;

const requestAccessToken = async () => {
  if (accessToken) return;

  const APP_URL = typeof window === 'undefined' ? process.env.APP_URL : '';
  const res = await fetch(`${APP_URL}/api/session`);

  if (res.ok) {
    const json = await res.json();
    accessToken = json.jwt;
  } else {
    accessToken = 'public';
  }
};

// remove cached token on 401 from the server
const resetTokenLink = onError(({ networkError }) => {
  if (networkError && networkError.name === 'ServerError' && networkError.statusCode === 401) {
    accessToken = null;
  }
});

const createHttpLink = (headers) => {
  const httpLink = new HttpLink({
    uri: 'https://omnixent-hasura.herokuapp.com/v1/graphql',
    credentials: 'include',
    headers, // auth token is fetched on the server side
    fetch,
  });
  return httpLink;
};

const createWSLink = () => {
  return new WebSocketLink(
    new SubscriptionClient('wss://omnixent-hasura.herokuapp.com/v1/graphql', {
      lazy: true,
      reconnect: true,
      timeout: 30000,
      connectionParams: async () => {
        await requestAccessToken(); // happens on the client
        return {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        };
      },
    }),
  );
};

export default function createApolloClient(initialState, headers) {
  const ssrMode = typeof window === 'undefined';
  let link;
  if (ssrMode) {
    link = createHttpLink(headers); // executed on server
  } else {
    link = createWSLink(); // executed on client
  }
  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache().restore(initialState),
  });
}
