import { IconButton } from '@chakra-ui/button';
import { Flex, Tag, useColorMode } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

export default function Header() {
  const { colorMode, toggleColorMode } = useColorMode();
  const ThemIcon = colorMode === 'light' ? SunIcon : MoonIcon;

  return (
    <Flex justifyContent="flex-end" alignItems="center" width="100%" pl={20} pr={20} pt={5} pb={5}>
      <Tag colorScheme="orange" mr={25} size="lg">
        {' '}
        Beta access{' '}
      </Tag>
      <IconButton aria-label="UI Theme" icon={<ThemIcon />} onClick={toggleColorMode} />
    </Flex>
  );
}
